import { FooterColumn, FooterColumns, Footer as LayoutFooter } from '@streamr/streamr-layout'
import React from 'react'
import { NewsletterForm } from 'streamr-ui'
import { MqDesktop, MqTablet } from 'streamr-ui/css/consts'
import styled from 'styled-components'
import Display from '~shared/Display'
import urls from '~utils/urls'
import { openNewsletterConfirmation } from '~utils'
import useTranslation from '~utils/useTranslation'
import Link from '../Link'
import SocialChannels from './SocialChannels'
import { APPS, NETWORK, RESOURCES, TOKEN } from '../Nav'

const UnstyledFooter = (props) => {
    const { t } = useTranslation()

    return (
        <LayoutFooter {...props}>
            <FooterColumns>
                <FooterColumn title={NETWORK}>
                    <Link to={urls.network}>Overview</Link>
                    <Link to={urls.stakeAndEarn}>Staking</Link>
                    <Link href={urls.apps.networkExplorer}>Explorer</Link>
                </FooterColumn>
                <FooterColumn title={RESOURCES}>
                    <Link href={urls.token}>{TOKEN}</Link>
                    <Link href={urls.docs.index}>Docs</Link>
                    <Link newTab href={urls.community.blog}>
                        Blog
                    </Link>
                    <Link href={urls.grants}>Grants</Link>
                    <Link to={urls.project.ecosystem}>Ecosystem</Link>
                    <Link to={urls.project.roadmap}>Roadmap</Link>
                    <Link to={urls.whitepapers}>Whitepapers</Link>
                    <Link to={urls.team}>Team</Link>
                    <Link to={urls.project.design}>Design assets</Link>
                    <Link to={urls.project.tokenMigration}>Token migration</Link>
                </FooterColumn>
                <FooterColumn title={APPS}>
                    <Link href={urls.apps.hub}>Hub</Link>
                    <Link to={urls.caseStudies.dataUnions}>Data Unions</Link>
                    <Link href="https://thechat.app/">Thechat.app</Link>
                </FooterColumn>
                <FooterColumn title="Contact">
                    <Link href={urls.contact.general}>{t('contacts.general')}</Link>
                    <Link href={urls.contact.media}>{t('contacts.media')}</Link>
                    <Link href={urls.contact.jobs}>{t('contacts.jobs')}</Link>
                    <Link href={urls.contact.business}>{t('contacts.business')}</Link>
                </FooterColumn>
                <FooterColumn title="Legal">
                    <Link newTab href={urls.documents.terms}>
                        {t('general.terms')}
                    </Link>
                    <Link newTab href={urls.documents.privacy}>
                        {t('general.privacy')}
                    </Link>
                </FooterColumn>
            </FooterColumns>
            <SocialChannels />
            <NewsletterForm
                formId={process.env.GATSBY_HUBSPOT_NEWSLETTER_FORM_ID}
                portalId={process.env.GATSBY_HUBSPOT_NEWSLETTER_PORTAL_ID}
                onFormSubmitted={(mounted) => {
                    if (mounted) {
                        openNewsletterConfirmation()
                    }
                }}
                title={
                    <>
                        <Display as="span" mobile="none" tablet="inline">
                            Subscribe to the Streamr newsletter
                        </Display>
                        <Display as="span" tablet="none">
                            Subscribe to the newsletter
                        </Display>
                    </>
                }
            />
        </LayoutFooter>
    )
}

const Footer = styled(UnstyledFooter)`
    color: #0c009a;
    background: #fcfcfc;

    ${SocialChannels} {
        svg {
            path {
                fill: #0c009a;
            }
        }
    }

    @media ${MqTablet} {
        ${SocialChannels},
        ${NewsletterForm} {
            margin-left: auto;
            margin-right: auto;
            max-width: 584px;
        }
    }

    @media ${MqDesktop} {
        ${SocialChannels},
        ${NewsletterForm} {
            max-width: none;
        }
    }
`

export default Footer
