export const Layer = {
    Drawer: 'drawers',
}

export const Timeframe = {
    All: 'ALL',
    Month: '1M',
    Year: '1Y',
    Week: '7D',
    Day: '24H',
}
