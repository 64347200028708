import 'normalize.css'
import '~utils/styles/ibm-plex.css'

import { Container } from 'toasterhea'
import React from 'react'
import { createGlobalStyle } from 'styled-components'
import Provider from 'streamr-client-react'
import { NavProvider } from '@streamr/streamr-layout'
import { Sans, Medium } from 'streamr-ui/css/consts'
import PageContext from '../contexts/PageContext'
import Head from '~shared/Head'
import MobileNav from '~shared/MobileNav'
import { Css as AppearCSS } from '~shared/Appear'
import { Layer } from '~utils/consts'

const Globals = createGlobalStyle`
    html,
    body {
        color: #323232;
        font-family: ${Sans};
        font-size: 16px;
        height: 100%;
        width: 100%;
    }

    strong {
        font-weight: ${Medium};
    }

    a,
    a:hover,
    a:visited,
    a:active,
    a:focus {
        color: inherit;
        text-decoration: underline;
    }

    a:hover,
    a:focus {
        text-decoration: none;
    }
`

export default function page(Component, options = {}) {
    return function Page({ pageContext }) {
        const { highlight, streamrClient = false, headComponent = <Head /> } = options

        return (
            <>
                <Globals />
                <AppearCSS />
                <PageContext.Provider value={pageContext}>
                    {headComponent}
                    <NavProvider highlight={highlight}>
                        <MobileNav />
                        {streamrClient ? (
                            <Provider>
                                <Component />
                            </Provider>
                        ) : (
                            <Component />
                        )}
                    </NavProvider>
                </PageContext.Provider>
                <Container id={Layer.Drawer} />
            </>
        )
    }
}
