import get from 'lodash/get'
import en from '../locales/en.po'

export default () => ({
    t: (path, params = {}, fallback = '') => {
        const translated = Object.keys(params).reduce(
            (memo, key) => memo.replace(`{{${key}}}`, params[key]),
            get(en, path, path),
        )

        if (translated === path) {
            if (!fallback) {
                console.warn(`😵 Untranslated key: "${path}"`)
            }

            return fallback
        }

        return translated
    },
})
